/**
 * Contact Box
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.section-contact h1 + p {
	margin-block-start: 4rem;
}

/* contact box */
.contact-box {
	margin: 1rem 0 4rem;
}

.contact-box--map {
	float: right;
	margin: 2.5rem 0 0 1rem;
	inline-size: 30%;
}

.contact-box--address h2 {
	margin: 0 0 1rem;
	font-weight: 600;
	font-size: 1.25em;
	line-height: 1.25;
	text-align: start;
}

.contact-box--address svg {
	inline-size: 1em;
	vertical-align: -0.125em;
}

/* phone box */
.phone-box {
	font-size: 1.25rem;
	align-self: center;
	text-align: center;
}

.phone-box--title {
	margin: 0;
	font-weight: 600;
}

.phone-box--icon {
	position: relative;
	margin: 1rem 0;
}

.phone-box--icon::before {
	content: '';
	position: absolute;
	inset-block-start: 50%;
	inset-inline: 0;
	z-index: 1;
	inline-size: 100%;
	border-block-end: 1px solid var(--color-lightgray);
}

.phone-box--icon::after {
	content: '';
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: 50%;
	z-index: 2;
	inline-size: 2rem;
	block-size: 2rem;
	border-radius: 100%;
	background-color: currentcolor;
	transform: translate(-50%, -50%);
}

.phone-box--icon svg:not(:root) {
	position: relative;
	z-index: 3;
	inline-size: 1rem;
	vertical-align: -0.125rem;
	fill: var(--color-white);
}

.phone-box--tel b {
	font-weight: 800;
}

/* email box */
.email-box {
	padding: 2rem 1rem;
	font-size: 1.25rem;
	align-self: center;
	text-align: center;
	background-color: var(--color-lightgray);
}

.email-box--title {
	margin: 0 0 1rem;
	font-weight: 600;
}

.email-box--button {
	font-size: 1rem;
}

/* contact teaser */
.logos-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	align-self: center;
	text-align: center;
}

.logos-list li:not(:first-child) {
	margin-block-start: 1rem;
}

/* contact info wrapper */
.contact-info {
	display: grid;
	grid-template: auto / auto;
	grid-gap: 2rem;
	margin: 4rem 0 0;
}

/* contact teaser */
.contact-teaser {
	margin: 4rem auto -3rem;
	padding: 1rem 1rem 2rem;
	border-radius: 1.5rem;
	max-inline-size: 45em;
	background-color: var(--color-lightgray);
	text-align: center;
}

@media (width >= 540px) {
	.contact-box {
		display: flex;
		margin-block: 0;
		padding: 1rem;
		border: 1px solid var(--color-lightgray);
	}

	.contact-box--map {
		float: none;
		flex-shrink: 0;
		margin-block-start: 0;
		margin-inline: 0 1rem;
		inline-size: auto;
	}

	.contact-box--address {
		flex-grow: 1;
	}

	.contact-teaser {
		margin-block-end: 0;
	}
}

@media (width >= 980px) {
	.contact-info {
		grid-template: auto / repeat(3, minmax(220px, 28%));
		grid-column-gap: 5%;
	}

	.contact-columns {
		display: grid;
		grid-template: auto / 61% calc(39% - 4rem);
		grid-gap: 4rem;
	}

	.contact-columns--address {
		padding: 4rem 0;
	}

	.contact-columns--address h2 {
		margin-block-start: 0;
	}
}
