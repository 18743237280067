/**
 * Forms
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* forms */
form {
	margin: 4rem 0;
}

/* text inputs */
input:is(
		[type='text'],
		[type='number'],
		[type='password'],
		[type='email'],
		[type='date'],
		[type='datetime-local'],
		[type='url']
	),
output,
textarea {
	display: block;
	margin: 0;
	padding: 1.5em 0.75em 0.25em;
	border: 0;
	border: 1px solid var(--color-lightgray);
	border-radius: 0;
	outline: 0;
	inline-size: 100%;
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.25;
	color: var(--color-gray);
	background-color: var(--color-white);
	background-clip: padding-box; /* removes inner shadow on iOS */
	box-shadow: none; /* removes validation outline in Firefox */
	box-sizing: border-box;
}

input:is(
		[type='text'],
		[type='number'],
		[type='password'],
		[type='email'],
		[type='date'],
		[type='datetime-local'],
		[type='url']
	):focus,
select:focus + output,
textarea:focus {
	border-color: var(--color-blue);
}

/* date inputs */
input[type='date'],
input[type='datetime-local'] {
	min-block-size: 50px; /* to fix inputs with an empty value */
	appearance: none; /* remove ugly iOS date input look */
}

input::-webkit-date-and-time-value {
	text-align: start;
}

/* checkboxe and radio buttons */
input[type='checkbox'],
input[type='radio'] {
	position: absolute;
	inset-block-start: 0.25ex;
	inset-inline-start: 0;
	margin: 0;
	padding: 0;
	border: 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	font-size: inherit;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
}

input[type='checkbox'] + .box {
	position: relative;
	display: inline-block;
	margin: 0.25ex 0.75ex 0 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	border: 1px solid var(--color-lightgray);
	color: var(--color-blue);
	background-color: var(--color-white);
	box-sizing: border-box;
	cursor: pointer;
}

input[type='checkbox'] + .box::after {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	inline-size: 100%;
	block-size: 100%;
	background-color: var(--color-blue);
	opacity: 0;
	pointer-events: none;
	transition: width 0.25s linear;
}

input[type='radio'] + .box {
	position: relative;
	display: inline-block;
	margin: 0.25ex 0.75ex 0 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	border: 1px solid var(--color-lightgray);
	border-radius: 100%;
	color: var(--color-blue);
	background-color: var(--color-white);
	box-sizing: border-box;
	cursor: pointer;
}

input[type='radio'] + .box::after {
	content: '';
	position: absolute;
	inset-block-start: 25%;
	inset-inline-start: 25%;
	inline-size: 50%;
	block-size: 50%;
	border-radius: 100%;
	background-color: currentcolor;
	opacity: 0;
	pointer-events: none;
	transform: scale(0.55);
	transition: transform 0.25s linear;
}

input[type='checkbox']:focus + .box,
input[type='radio']:focus + .box {
	border-color: var(--color-blue);
}

input[type='checkbox']:checked + .box {
	border-color: transparent;
	color: var(--color-white);
	background-color: var(--color-blue);
	background-image: inline('check.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 90%;
}

input[type='checkbox']:checked + .box::after {
	opacity: 1;
	inline-size: 0;
}

input[type='radio']:checked + .box::after {
	opacity: 1;
	transform: scale(1);
}

/* disabled inputs */
input:is([readonly], [disabled]) {
	color: hsl(from var(--color-gray) h s 65%);
	background-color: transparent;
	cursor: default;
}

/* dropdowns */
.dropdown {
	display: block;
	position: relative;
}

.dropdown::after {
	content: '';
	position: absolute;
	inset-block-start: calc(50% - 0.25em);
	inset-inline-end: 1em;
	z-index: 2;
	border-block-start: 0.5em solid var(--color-blue);
	border-inline-end: 0.5em solid transparent;
	border-inline-start: 0.5em solid transparent;
	border-block-end: 0;
}

.dropdown select {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 1;
	inline-size: 100%;
	block-size: 100%;
	cursor: pointer;
	opacity: 0;
}

/* selections (wrapper for radio and check boxes) */
.selection {
	display: flex;
	position: relative;
}

.selection .box {
	flex-grow: 0;
	flex-shrink: 0;
}

/* lists */
form ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

form ol li {
	position: relative;
	margin-block-end: 1rem;
}

/* labels */
form label:is([for]) {
	position: absolute;
	inset-block-start: 1px;
	inset-inline: 1px;
	z-index: 2;
	padding: 0.25rem 0.75rem;
	font-size: 0.75rem;
	background-color: var(--color-white);
}

form .toolbar ~ label:is([for]) {
	inset-block-start: 34px;
}

form label:is([for^='image'], [for^='file']) {
	display: block;
	position: static;
	padding: 0;
}

/* errors */
form label.error:is([for]) {
	display: block;
	position: static;
	padding: 0;
	font-size: 0.75em;
	color: var(--color-red);
	cursor: pointer;
}

form label.error:is([for]) a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
}

form label.error:is([for])::before {
	content: '!';
	display: inline-block;
	margin-inline-end: 1ex;
	inline-size: 1.5em;
	block-size: 1.5em;
	border-radius: 100%;
	font-weight: 600;
	text-align: center;
	color: var(--color-white);
	background-color: var(--color-red);
}

form button + label.error:is([for]) {
	margin-block-start: 0.5rem;
}

/* descriptions */
form small {
	display: block;
	margin-block-start: 2px;
	font-size: 0.75em;
}

/* multiline-inputs */
textarea {
	margin: 0; /* address margins set differently in Firefox 4+, Safari, and Chrome */
	overflow: auto; /* removes default vertical scrollbar in 8/9/10/11 */
	font: inherit; /* correct font properties not being inherited */
	color: inherit; /* correct color not being inherited */
	vertical-align: top; /* improves readability and alignment in all browsers */
}

/* disabled inputs */
textarea:is([readonly], [disabled]) {
	color: hsl(from var(--color-gray) h s l 65%);
	background-color: transparent;
	cursor: default;
}

/* dropdowns */
select {
	margin: 0; /* address margins set differently in Firefox 4+, Safari, and Chrome */
	font: inherit; /* correct font properties not being inherited */
	color: inherit; /* correct color not being inherited */
	text-transform: none; /* correct `select` style inheritance in Firefox */
	vertical-align: baseline;
}

/* disabled inputs */
select[disabled] {
	cursor: default;
}

optgroup {
	margin: 0; /* address margins set differently in Firefox 4+, Safari, and Chrome */
	font: inherit; /* correct font properties not being inherited */
	font-weight: 600; /* don't inherit the `font-weight` (applied by a rule above) */
	color: inherit; /* correct color not being inherited */
}

/* input group with buttons */
.input-group {
	display: flex;
}

.input-group input {
	flex: 1 0 auto;
	position: relative;
	inline-size: auto;
}

.input-group input:focus {
	z-index: 1;
}

.input-group button {
	flex: 0 0 auto;
	display: inline-flex;
	align-items: center;
	position: relative;
	margin-inline-start: -1px;
	padding: 0 1em;
	border: 1px solid var(--color-lightgray);
	border-radius: 0;
	color: inherit;
	background-color: transparent;
	transition: none;
}

.input-group button:hover {
	z-index: 1;
	border-color: currentcolor;
	color: var(--color-blue);
}

.input-group button svg {
	pointer-events: none;
}

form label[for].input-group--label {
	inset-inline-end: auto;
}

/* form actions */
.actions {
	text-align: center;
}

/* form in details */
details {
	margin: 3rem 0;
}

details summary {
	cursor: pointer;
}

details form {
	margin-block-start: 1rem;
}

@media (width >= 540px) {
	.actions {
		text-align: inherit;
	}
}
