/**
 * Layout
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* root elements */
html {
	min-block-size: 100%;
	overflow-y: scroll; /* provides scrollbars to avoid jumping centered layouts */
	color: var(--color-gray);
	background-color: var(--color-brightblue);
	background-image: linear-gradient(
		to bottom,
		var(--color-darkblue),
		var(--color-brightblue) 1200px
	);
	background-repeat: no-repeat;
	background-attachment: fixed;
}

body {
	margin: 0 0 6rem;
}

.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 auto;
	inline-size: 100%;
	max-inline-size: 72.5rem; /* respond on smaller screens */
	color: inherit;
	background-color: var(--color-white);
}

/* main content */
main {
	grid-area: main;
	display: block; /* correct `block` display not defined in IE 8/9/10/11. */
	padding: 0 1rem 4rem;
	box-sizing: border-box;
}

@media (width >= 375px) {
	body {
		margin-inline: 1rem;
	}

	.wrapper {
		border-end-start-radius: 1.5rem;
		border-end-end-radius: 1.5rem;
	}
}

@media (width >= 540px) {
	body {
		margin-block-start: 2rem;
	}

	.wrapper {
		border-start-start-radius: 1.5rem;
		border-start-end-radius: 1.5rem;
	}

	main {
		padding-inline: 2rem;
	}
}

@media (width >= 768px) {
	body {
		margin-block-start: 4rem;
		margin-inline: 2rem;
	}

	main {
		padding-inline: 4rem;
	}
}

@media (width >= 1024px) {
	.wrapper {
		display: grid;
		grid-template: auto / 360px 1fr;
		grid-template-areas: 'header navigation' 'main main';
	}
}

/* printed page layout */
@media print {
	html {
		orphans: 4;
		widows: 2;
	}

	html,
	body {
		margin: 0;
		padding: 0;
		color: #000;
		background-color: #fff;
	}

	.wrapper {
		inline-size: 100%;
		max-inline-size: none;
		color: #000;
		background-color: #fff;
	}
}
