/**
 * Code
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* inline code */
code {
	font-family: var(--font-stack-monospace);
	font-size: 1em;
	color: var(--color-blue);
}

/* block code */
pre {
	margin: 2em 0;
	max-block-size: 20em;
	overflow: auto; /* contain overflow in all browsers */
	font-family: var(--font-stack-monospace);
	font-size: 1em;
	color: var(--color-blue);
	tab-size: 4;
	white-space: pre-wrap;
	word-wrap: break-word;
}
