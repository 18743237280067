/**
 * Footer
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* footer */
footer {
	display: block; /* correct `block` display not defined in IE 8/9 */
}

.page-footer {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	margin: 1rem auto 0;
	padding: 0 2rem;
	inline-size: 100%;
	max-inline-size: 72.5rem; /* respond on smaller screens */
	font-size: 0.75rem;
	text-align: center;
	color: var(--color-white);
	box-sizing: border-box;
	clear: both; /* footers should clear all content floats */
}

.page-footer a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
	font-weight: normal;
}

/* social media links */
.social-media-links {
	justify-self: end;
	display: flex;
	margin: 1rem 0 0;
	padding: 0;
	list-style-type: none;
}

.social-media-links li:not(:first-child) {
	margin-inline-start: 1rem;
}

@media (width >= 540px) {
	.page-footer {
		display: grid;
		grid-template: 1fr / 1fr auto;
		margin-block-start: 0;
		text-align: start;
	}
}

@media (width >= 768px) {
	.page-footer {
		padding-inline: 4rem;
	}
}

@media print {
	.social-media-links {
		display: none;
	}
}
