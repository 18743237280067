/**
 * Toolbar
 * @project PIBSI
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* toolbar */
.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-end: 1px;
	padding-inline-end: 0.5rem;
	background-color: var(--color-white);
}

.toolbar > * {
	display: flex;
	align-items: center;
}

/* toolbar links */
.toolbar a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
	fill: currentcolor;
}

/* toolbar buttons */
.button-toolbar,
.button-toolbar:is(:link, :visited, :hover, :focus, :active) {
	padding: 0.5rem;
	border: 0;
	inline-size: 2rem;
	block-size: 2rem;
	overflow: hidden;
	font-size: 0.75em;
	color: inherit;
	background-color: transparent;
	box-sizing: border-box;
	transition: color 0.2s linear;
}

@media (hover: hover) {
	.toolbar a:is(:hover),
	.button-toolbar:is(:hover) {
		color: var(--color-blue);
	}
}

@media print {
	.toolbar {
		display: none;
	}
}
