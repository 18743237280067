/**
 * Selections
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

::selection {
	color: var(--color-white);
	background-color: var(--color-blue);
}
