/**
 * Links
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* links */
a:is(:link, :visited, :hover, :focus, :active) {
	font-weight: 600;
	color: var(--color-blue);
	background-color: transparent;
	-webkit-tap-highlight-color: hsl(from var(--color-blue) h s l / 15%);
}

a:is(:link, :visited) {
	text-decoration: none;
}

a:is(:hover, :focus, :active) {
	text-decoration: underline;
}

/* link blocks */
.link-blocks {
	margin: 1rem 0;
	padding: 0;
	text-align: center;
	list-style-type: none;
}

.link-blocks li:not(:first-child) {
	margin-block-start: 1rem;
}

.link-blocks svg {
	margin-inline-end: 0.5rem;
	inline-size: 1.75em;
	block-size: 1.75em;
	fill: currentcolor;
}

.link-blocks a:is(:link, :visited, :hover, :focus, :active) {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border-radius: 6px;
	color: inherit;
	background-color: var(--color-lightgray);
	text-decoration: none;
	transition-property: color, background-color;
	transition-duration: 0.2s;
	transition-timing-function: linear;
}

@media (hover: hover) {
	.link-blocks a:hover {
		color: var(--color-white);
		background-color: var(--color-blue);
	}
}

@supports (display: grid) {
	.link-blocks {
		display: grid;
		grid-template: auto / 1fr;
		grid-gap: 1em;
	}

	.link-blocks li:not(:first-child) {
		margin-block-start: 0;
	}

	@media (width >= 475px) {
		.link-blocks {
			grid-template: auto / repeat(2, 1fr);
		}
	}
}

/* printed links */
@media print {
	a[href] {
		color: var(--color-blue);
		background-color: var(--color-white);
		text-decoration: underline;
	}
}
