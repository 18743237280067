/**
 * Images
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* images */
img {
	color: var(--color-gray);
	background-color: transparent;
	vertical-align: bottom;
	max-inline-size: 100%;
	block-size: auto;
	border: 0; /* removes border when inside `a` element in IE 8/9/10 */
}

/* images in Google Maps */
.gm-style img {
	max-inline-size: none;
}

/* SVG images */
svg:not(:root) {
	overflow: hidden; /* correct overflow not hidden in IE 9/10/11 */
	fill: currentcolor;
}

/* canvas */
canvas {
	display: inline-block; /* correct `inline-block` display not defined in IE 8/9 */
}

/* printed images */
@media print {
	img {
		max-inline-size: 100%; /* prevents images from being cut when they are larger than page width */
	}
}
