/**
 * Abbreviations
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

abbr[title] {
	white-space: nowrap;
	text-decoration: none;
	border-block-end: 0;
	cursor: help;
}
