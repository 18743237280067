/**
 * Buttons
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

button,
.button,
.button:is(:link, :visited, :hover, :focus, :active) {
	position: relative;
	display: inline-block;
	margin: 0; /* address margins set differently in Firefox 4+, Safari, and Chrome */
	padding: calc(0.5em + 2px) calc(2em + 2px);
	border: 0;
	border-radius: 100px;
	overflow: visible; /* address `overflow` set to `hidden` in IE 8/9/10/11 */
	font: inherit; /* correct font properties not being inherited */
	font-weight: 600;
	text-decoration: none;
	text-align: center;
	text-transform: none; /* correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera */
	white-space: nowrap;
	color: var(--color-white);
	background: var(--color-blue);
	cursor: pointer; /* improve usability and consistency of cursor style between image-type `input` and others. */
	transition: all 0.2s linear;
	box-sizing: border-box;
}

/* disabled state */
button[disabled],
button[disabled]:is(:hover, :focus),
.button[aria-disabled='true'],
.button[aria-disabled='true']:is(:hover, :focus) {
	opacity: 0.3;
	cursor: not-allowed;
}

.button[aria-disabled='true'] {
	pointer-events: none;
}

/* removes inner padding and border in FF3+ */
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

/* selected state */
button:is(:hover, :focus),
.button:is(:hover, :focus) {
	background: var(--color-darkblue);
}

/* secondary button */
.button-secondary,
.button-secondary:is(:link, :visited, :hover, :focus, :active) {
	padding: 0.5em 2em;
	border: 2px solid currentcolor;
	color: var(--color-blue);
	background: var(--color-white);
}

/* selected state */
.button-secondary:is(:hover, :focus) {
	color: var(--color-darkblue);
}

/* button rows (e.g. submit/reset in forms) */
.button-row {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.5em;
	justify-content: space-between;
}

/* buttons looking like links */
.link-button {
	display: inline;
	padding: 0;
	border-radius: 0;
	outline: 0;
	white-space: normal;
	text-decoration: none;
	color: var(--color-blue);
	background: none;
	transition: none;
}

.link-button:is(:hover, :focus, :active) {
	background: none;
	text-decoration: underline;
}

@media (width >= 928px) {
	.button-row {
		grid-template-columns: repeat(2, auto);
	}
}

@media (width <= 927px) {
	form button[type='submit']:only-of-type {
		inline-size: 100%;
	}
}

@media print {
	.button,
	.button:is(:link, :visited, :hover, :focus, :active) {
		color: inherit;
		background-color: transparent;
		border-color: currentcolor;
	}
}
