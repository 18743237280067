/**
 * Tables
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

table {
	margin: 3rem 0;
	border: 1px solid var(--color-lightgray);
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 1.25;
	inline-size: 100%;
}

caption {
	display: none;
}

th {
	padding: 0.25rem 0.5rem;
	color: var(--color-gray);
	background-color: var(--color-lightgray);
	font-size: 0.75rem;
	font-weight: normal;
	text-transform: uppercase;
	text-align: start;
	text-shadow: 1px 1px 1px var(--color-white);
}

td {
	padding: 0.25rem 0.5rem;
}

tr:nth-child(even) td {
	background-color: hsl(from var(--color-lightgray) h s 97%);
}
