/**
 * Products
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.product {
	margin: 4rem 0 0;
}

.product--image {
	float: right;
	margin: 0 0 0.5rem 0.5rem;
	inline-size: 35%;
}

.product--name {
	margin-block-start: 0;
}

.product--price {
	margin: 1rem 0 0;
	text-align: center;
}

@media (width >= 540px) {
	.product {
		display: flex;
	}

	.product--image {
		float: none;
		inline-size: auto;
		flex-shrink: 0;
		margin: 0 2rem 0 0;
	}

	.product--content {
		flex-grow: 1;
	}

	.product--price {
		text-align: inherit;
	}
}
