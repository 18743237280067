/**
 * Quotes
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

blockquote {
	margin: 1rem 0 0;
	padding: 0;
}

blockquote p:first-child::before {
	content: open-quote;
}

blockquote p:last-child::after {
	content: close-quote;
}

cite {
	display: block;
	font-style: normal;
	font-weight: 600;
	font-size: 0.875rem;
}

cite a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
}

/* quotes box */
.quotes-box {
	margin: 0 0 6rem;
	text-align: center;
}

.quotes-box--list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

@media (width >= 540px) {
	blockquote {
		font-size: 1.25em;
	}
}
