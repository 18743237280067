/**
 * Team
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* team members */
.team-member {
	margin: 4rem auto 0;
	max-inline-size: 45em;
}

.team-member--photo {
	margin-block-end: -1.5rem;
	inline-size: 50%;
}

.team-member--photo figure {
	position: relative;
	padding-block-end: 25px;
}

.team-member--photo figure::after {
	content: '';
	position: absolute;
	inset-block-end: 0;
	inset-inline-start: 0;
	z-index: 1;
	inline-size: 100%;
	block-size: 50px;
	background-image: radial-gradient(
		closest-side,
		hsl(from var(--color-gray) h s l / 40%),
		var(--color-white)
	);
}

.team-member--photo img {
	position: relative;
	z-index: 2;
	background-color: var(--color-white);
	border-block-end: 1px solid var(--color-white);
}

.team-member--details {
	flex: 1 1 auto;
}

.team-member--details h2 + p {
	margin: -1rem 0 1rem;
	color: hsl(from var(--color-gray) h s 75%);
}

@media (width >= 540px) {
	.team-member {
		display: flex;
		flex-direction: row;
	}

	.team-member:nth-child(odd) {
		flex-direction: row-reverse;
	}

	.team-member--photo {
		flex: 0 0 35%;
		margin-block-end: 0;
		margin-inline-end: 2rem;
		inline-size: auto;
	}

	.team-member:nth-child(odd) .team-member--photo {
		margin-inline: 2rem auto;
	}
}

@media print {
	.team-member {
		page-break-inside: avoid;
	}
}
