/**
 * Jobs
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.job--listing {
	margin: 4.5rem 0 1rem;
	padding: 0;
	list-style: none;

	li {
		display: grid;
		grid-template-areas: 'type' 'title' 'link';
		gap: 0.5rem;

		&:not(:first-child) {
			margin-block-start: 2rem;
			padding-block-start: 2rem;
			border-block-start: 2px solid var(--color-lightgray);
		}
	}
}

.job--title {
	grid-area: title;
	margin: 0;
	font-size: 1.25rem;
	text-align: start;
}

.job--subtitle {
	font-family: Caveat, cursive;
	font-size: 2rem;
	line-height: 1.25;
	text-align: center;
}

.job--description {
	h2 {
		margin: 2rem 0 1rem;
		font-weight: 600;
		font-size: 1em;
		line-height: 1.25;
		text-align: start;
	}
}

.job--link {
	grid-area: link;
	align-self: center;

	.button {
		inline-size: 100%;
	}
}

.job--type {
	grid-area: type;

	svg {
		vertical-align: -0.125ex;
		fill: var(--color-lightgray);
	}

	h1 + & {
		font-size: 1.25rem;
		font-weight: 600;
		text-align: center;
	}
}

.job--application {
	margin-block-start: 2rem;
	padding-block-start: 2rem;
	border-block-start: 2px solid var(--color-lightgray);

	h2 {
		margin: 1rem 0;
		text-align: start;
	}

	img {
		border-radius: 100%;
	}
}

.job--actions {
	margin: 2rem 0;

	p:first-of-type {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}

.job--teaser {
	margin: 2rem 0;
	text-align: center;
}

@media (width >= 540px) {
	.job--listing {
		li {
			grid-template-areas: 'type link' 'title link';
			grid-template-columns: 1fr max-content;
			gap: 0 1.25rem;
		}
	}

	.job--description {
		h2 {
			font-size: 1.25em;
		}
	}

	.job--link {
		.button {
			inline-size: auto;
		}
	}

	.job--actions {
		p:first-of-type {
			flex-direction: row;
		}
	}
}

@media (width >= 980px) {
	.jobs-columns {
		display: grid;
		grid-template: auto / 61% calc(39% - 4rem);
		grid-gap: 4rem;
		margin: 4rem 0 1rem;
	}

	.job--subtitle {
		text-align: start;
	}

	.job--application {
		margin-block-start: 0;
		padding: 1rem;
		border-block-start: 0;
		background-color: var(--color-lightgray);
	}
}
