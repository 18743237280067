/**
 * Lists
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* unordered lists */
ul {
	margin: 1rem 0 0;
	padding: 0 0 0 1em;
	list-style: square;
}

ul li {
	margin: 0;
	padding: 0;
}

ul li::marker {
	color: var(--color-blue);
}

/* ordered lists */
ol {
	margin: 1rem 0 0;
	padding: 0 0 0 2.5em;
	list-style: decimal;
}

ol li {
	margin: 0;
	padding: 0;
}

/* definition lists */
dl {
	margin: 1rem 0 0;
}

dl::before,
dl::after {
	content: ' ';
	display: table;
}

dl::after {
	clear: both;
}

dt {
	float: left;
	inline-size: 12em;
	font-weight: 600;
}

dd {
	margin: 0 0 0 13em;
}

/* downloads */
.downloads {
	display: grid;
	gap: 0.25rem;
	padding: 0;
	list-style: none;
}

.downloads li {
	display: grid;
	grid-template: 1fr / 1em max-content;
	gap: 0.25rem;
}

.downloads .file-icon {
	margin-block-start: 0.125em;
}

/* privacy summary */
.privacy-summary {
	display: flex;
	margin: 3rem 0;
	padding: 0;
	text-align: center;
	list-style-type: none;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.privacy-summary li {
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-inline-start: 1rem;
	padding: 1rem;
	border-radius: 6px;
	background-color: hsl(from var(--color-lightgray) h s 90%);
	min-inline-size: 175px;
	box-sizing: border-box;
}

.privacy-summary li:first-child {
	margin-inline-start: 0;
}

.privacy-summary svg:not(:root) {
	margin-block-end: 0.25rem;
	fill: var(--color-blue);
}

/* admin items */
.admin-items {
	margin: 3rem 0;
	border: 1px solid var(--color-lightgray);
	line-height: 1.25;
}

.admin-items h2 {
	margin: 0;
	padding: 0.25rem 0.5rem;
	color: var(--color-gray);
	background-color: var(--color-lightgray);
	font-size: 0.75rem;
	font-weight: normal;
	text-transform: uppercase;
	text-align: start;
	text-shadow: 1px 1px 1px var(--color-white);
}

.admin-items ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.admin-items ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 0.5rem;
}

.admin-items ul li:nth-child(odd) {
	background-color: var(--color-white);
}

.admin-items ul li:nth-child(even) {
	background-color: hsl(from var(--color-lightgray) h s 97%);
}

.admin-items time {
	display: block;
	font-size: 0.75em;
	font-weight: 600;
}

.admin-items small {
	font-size: 0.75em;
	color: var(--color-red);
}

.admin-items-title {
	a:is(:link, :visited, :hover, :focus, :active) {
		font-weight: normal;
	}
}

.admin-items-actions {
	flex: 0 0 5em;
	text-align: end;
	white-space: nowrap;
}

.admin-items-actions form {
	display: inline;
	margin: 0;
}

.admin-items-actions .link-button,
.admin-items-actions .link-button:is(:link, :visited, :hover, :focus, :active) {
	padding-inline-end: 0.5rem;
	inline-size: auto !important;
}

.admin-items-actions .drag-handle {
	display: inline-block;
	inline-size: 0.625em;
	block-size: 0.9375em;
	color: var(--color-blue);
	background-image: radial-gradient(
		circle at center,
		currentcolor 0%,
		currentcolor 50%,
		transparent 51%
	);
	background-size: 0.3125em 0.3125em;
	vertical-align: -0.125em;
	cursor: move;
}
