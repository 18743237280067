/**
 * Tags
 * @project PIBSI
 * @source Tagify <https://github.com/yairEO/tagify>
 * @author Marc Görtz <https://marcgoertz.de/>
 */

/* stylelint-disable selector-class-pattern -- Tagify library styles */

@charset 'utf-8';

:root {
	--tagify-dd-color-primary: #0082b9;
	--tagify-dd-bg-color: #fff;
}

.tagify {
	--tags-border-color: #ddd;
	--tags-hover-border-color: #ccc;
	--tags-focus-border-color: #0082b9;
	--tag-bg: #e5e5e5;
	--tag-hover: #d3e2e2;
	--tag-text-color: #262626;
	--tag-text-color-edit: #262626;
	--tag-pad: 0.3em 0.5em;
	--tag-inset-shadow-size: 1.1em;
	--tag-invalid-color: #d39494;
	--tag-invalid-bg: rgb(211 148 148 / 50%);
	--tag-remove-bg: rgb(211 148 148 / 30%);
	--tag-remove-btn-color: #262626;
	--tag-remove-btn-bg: none;
	--tag-remove-btn-bg-hover: #c77777;
	--input-color: inherit;
	--tag-min-width: 1ch;
	--tag-max-width: auto;
	--tag-hide-transition: 0.3s;
	--placeholder-color: rgb(0 0 0 / 40%);
	--placeholder-color-focus: rgb(0 0 0 / 25%);
	--loader-size: 0.8em;

	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	border: 1px solid var(--tags-border-color);
	padding: 1.5em 0.5em 0.25em;
	line-height: normal;
	cursor: text;
	outline: 0;
	position: relative;
	box-sizing: border-box;
	transition: 0.1s;
}

@keyframes tags-bump {
	30% {
		transform: scale(1.2);
	}
}

@keyframes rotate-loader {
	to {
		transform: rotate(1turn);
	}
}

.tagify:hover {
	border-color: var(--tags-hover-border-color);
}

.tagify.tagify--focus {
	transition: 0s;
	border-color: var(--tags-focus-border-color);
}

.tagify[readonly]:not(.tagify--mix) {
	cursor: default;
}

.tagify[readonly]:not(.tagify--mix) > .tagify__input {
	visibility: hidden;
	inline-size: 0;
	margin: 5px 0;
}

.tagify[readonly]:not(.tagify--mix) .tagify__tag > div {
	padding: 0.3em 0.5em;
	padding: var(--tag-pad);
}

.tagify[readonly]:not(.tagify--mix) .tagify__tag > div::before {
	background: linear-gradient(
			45deg,
			var(--tag-bg) 25%,
			transparent 25%,
			transparent 50%,
			var(--tag-bg) 50%,
			var(--tag-bg) 75%,
			transparent 75%,
			transparent
		)
		0/5px 5px;
	box-shadow: none;
	filter: brightness(0.95);
}

.tagify[readonly] .tagify__tag__removeBtn {
	display: none;
}

.tagify--loading .tagify__input > br:last-child {
	display: none;
}

.tagify--loading .tagify__input::before {
	content: none;
}

.tagify--loading .tagify__input::after {
	content: '';
	vertical-align: middle;
	opacity: 1;
	inline-size: var(--loader-size);
	block-size: var(--loader-size);
	border: 3px solid;
	border-color: #eee #bbb #888 transparent;
	border-radius: 50%;
	animation: rotate-loader 0.4s infinite linear;
	margin: -2px 0 -2px 0.5em;
}

.tagify--loading .tagify__input:empty::after {
	margin-inline-start: 0;
}

.tagify + input,
.tagify + textarea {
	position: absolute !important;
	inset-inline-start: -9999em !important;
	transform: scale(0) !important;
}

.tagify__tag {
	display: inline-flex;
	align-items: center;
	margin: 5px 0 5px 5px;
	position: relative;
	z-index: 1;
	outline: 0;
	cursor: default;
	transition: 0.13s ease-out;
}

.tagify__tag > div {
	vertical-align: top;
	box-sizing: border-box;
	max-inline-size: 100%;
	padding: 0.3em 0.5em;
	padding: var(--tag-pad, 0.3em 0.5em);
	color: var(--tag-text-color, #000);
	line-height: inherit;
	border-radius: 3px;
	white-space: nowrap;
	transition: 0.13s ease-out;
}

.tagify__tag > div > * {
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: top;
	min-inline-size: var(--tag-min-width, 1ch);
	max-inline-size: var(--tag-max-width, auto);
	transition:
		0.8s ease,
		0.1s color;
}

.tagify__tag > div > [contenteditable] {
	outline: 0;
	user-select: text;
	cursor: text;
	margin: -2px;
	padding: 2px;
	max-inline-size: 350px;
}

.tagify__tag > div::before {
	content: '';
	position: absolute;
	border-radius: inherit;
	inset: 0;
	z-index: -1;
	pointer-events: none;
	transition: 120ms ease;
	animation: tags-bump 0.3s ease-out 1;
	box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-bg, #e5e5e5)
		inset;
}

.tagify__tag:focus div::before,
.tagify__tag:hover:not([readonly]) div::before {
	inset: -2px;
	box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em)
		var(--tag-hover, #d3e2e2) inset;
}

.tagify__tag--loading {
	pointer-events: none;
}

.tagify__tag--loading .tagify__tag__removeBtn {
	display: none;
}

.tagify__tag--loading::after {
	--loader-size: 0.4em;

	content: '';
	vertical-align: middle;
	opacity: 1;
	inline-size: var(--loader-size);
	block-size: var(--loader-size);
	border: 3px solid;
	border-color: #eee #bbb #888 transparent;
	border-radius: 50%;
	animation: rotate-loader 0.4s infinite linear;
	margin: 0 0.5em 0 -0.1em;
}

.tagify__tag--flash div::before {
	animation: none;
}

.tagify__tag--hide {
	inline-size: 0 !important;
	padding-inline: 0;
	margin-inline: 0;
	opacity: 0;
	transform: scale(0);
	transition: 0.3s;
	transition: var(--tag-hide-transition, 0.3s);
	pointer-events: none;
}

.tagify__tag--hide > div > * {
	white-space: nowrap;
}

.tagify__tag.tagify--noAnim > div::before {
	animation: none;
}

.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
	opacity: 0.5;
}

.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
	box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em)
		var(--tag-invalid-bg, rgb(211 148 148 / 50%)) inset !important;
	transition: 0.2s;
}

.tagify__tag[readonly] .tagify__tag__removeBtn {
	display: none;
}

.tagify__tag[readonly] > div::before {
	background: linear-gradient(
			45deg,
			var(--tag-bg) 25%,
			transparent 25%,
			transparent 50%,
			var(--tag-bg) 50%,
			var(--tag-bg) 75%,
			transparent 75%,
			transparent
		)
		0/5px 5px;
	box-shadow: none;
	filter: brightness(0.95);
}

.tagify__tag--editable > div {
	color: var(--tag-text-color-edit, #000);
}

.tagify__tag--editable > div::before {
	box-shadow: 0 0 0 2px var(--tag-hover, #d3e2e2) inset !important;
}

.tagify__tag--editable > .tagify__tag__removeBtn {
	pointer-events: none;
}

.tagify__tag--editable > .tagify__tag__removeBtn::after {
	opacity: 0;
	transform: translateX(100%) translateX(5px);
}

.tagify__tag--editable.tagify--invalid > div::before {
	box-shadow: 0 0 0 2px var(--tag-invalid-color, #d39494) inset !important;
}

.tagify__tag__removeBtn {
	order: 5;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	cursor: pointer;
	font: 14px/1 sans-serif;
	background: var(--tag-remove-btn-bg, none);
	color: #000;
	color: var(--tag-remove-btn-color, #000);
	inline-size: 14px;
	block-size: 14px;
	margin-inline: auto 4.6667px;
	overflow: hidden;
	transition: 0.2s ease-out;
}

.tagify__tag__removeBtn::after {
	content: '\00D7';
	transition:
		0.3s,
		color 0s;
}

.tagify__tag__removeBtn:hover {
	color: #fff;
	background: var(--tag-remove-btn-bg-hover, #c77777);
}

.tagify__tag__removeBtn:hover + div > span {
	opacity: 0.5;
}

.tagify__tag__removeBtn:hover + div::before {
	box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em)
		var(--tag-remove-bg, rgb(211 148 148 / 30%)) inset !important;
	transition: box-shadow 0.2s;
}

.tagify:not(.tagify--mix) .tagify__input br {
	display: none;
}

.tagify:not(.tagify--mix) .tagify__input * {
	display: inline;
	white-space: nowrap;
}

.tagify__input {
	flex-grow: 1;
	display: inline-block;
	min-inline-size: 110px;
	margin: 5px;
	padding: var(--tag-pad, 0.3em 0.5em);
	line-height: inherit;
	position: relative;
	white-space: pre-wrap;
	color: var(--input-color, inherit);
	box-sizing: inherit;
}

.tagify__input:empty::before {
	transition: 0.2s ease-out;
	opacity: 1;
	transform: none;
	display: inline-block;
	inline-size: auto;
}

.tagify--mix .tagify__input:empty::before {
	display: inline-block;
}

.tagify__input:focus {
	outline: 0;
}

.tagify__input:focus::before {
	transition: 0.2s ease-out;
	opacity: 0;
	transform: translateX(6px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.tagify__input:focus::before {
		display: none;
	}
}

@supports (-ms-ime-align: auto) {
	.tagify__input:focus::before {
		display: none;
	}
}

.tagify__input:focus:empty::before {
	transition: 0.2s ease-out;
	opacity: 1;
	transform: none;
	color: var(--placeholder-color-focus);
}

.tagify__input::before {
	content: attr(data-placeholder);
	block-size: 1em;
	line-height: 1em;
	margin: auto 0;
	z-index: 1;
	color: var(--placeholder-color);
	white-space: nowrap;
	pointer-events: none;
	opacity: 0;
	position: absolute;
}

.tagify__input::after {
	content: attr(data-suggest);
	display: inline-block;
	white-space: pre;
	color: #000;
	opacity: 0.3;
	pointer-events: none;
	max-inline-size: 100px;
}

.tagify__input .tagify__tag {
	margin: 0 1px;
}

.tagify__input .tagify__tag > div {
	padding-block: 0;
}

.tagify--mix {
	display: block;
}

.tagify--mix .tagify__input {
	padding: 5px;
	margin: 0;
	inline-size: 100%;
	block-size: 100%;
	line-height: 1.5;
	display: block;
}

.tagify--mix .tagify__input::before {
	display: none;
	position: static;
	line-height: inherit;
	block-size: auto;
}

.tagify--mix .tagify__input::after {
	content: none;
}

.tagify--select::after {
	content: '>';
	opacity: 0.5;
	position: absolute;
	inset-block: 50% 0;
	inset-inline-end: 0;
	font: 16px monospace;
	line-height: 8px;
	block-size: 8px;
	pointer-events: none;
	transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
	transition: 0.2s ease-in-out;
}

.tagify--select[aria-expanded='true']::after {
	transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2);
}

.tagify--select .tagify__tag {
	position: absolute;
	inset-block: 0;
	inset-inline-end: 1.8em;
}

.tagify--select .tagify__tag div {
	display: none;
}

.tagify--select .tagify__input {
	inline-size: 100%;
}

.tagify--invalid {
	--tags-border-color: #d39494;
}

.tagify__dropdown {
	position: absolute;
	z-index: 9999;
	transform: translateY(1px);
	overflow: hidden;
}

.tagify__dropdown[placement='top'] {
	margin-block-start: 0;
	transform: translateY(-100%);
}

.tagify__dropdown[placement='top'] .tagify__dropdown__wrapper {
	border-block-start-width: 1px;
	border-block-end-width: 0;
}

.tagify__dropdown[position='text'] {
	box-shadow: 0 0 0 3px rgb(var(--tagify-dd-color-primary) 0.1);
	font-size: 0.9em;
}

.tagify__dropdown[position='text'] .tagify__dropdown__wrapper {
	border-width: 1px;
}

.tagify__dropdown__wrapper {
	max-block-size: 300px;
	overflow: hidden;
	background: var(--tagify-dd-bg-color);
	border: 1px solid #3595f6;
	border-color: var(--tagify-dd-color-primary);
	border-width: 1.1px;
	border-block-start-width: 0;
	box-shadow: 0 2px 4px -2px rgb(0 0 0 / 20%);
	transition: 0.25s cubic-bezier(0, 1, 0.5, 1);
}

.tagify__dropdown__wrapper:hover {
	overflow: auto;
}

.tagify__dropdown--initial .tagify__dropdown__wrapper {
	max-block-size: 20px;
	transform: translateY(-1em);
}

.tagify__dropdown--initial[placement='top'] .tagify__dropdown__wrapper {
	transform: translateY(2em);
}

.tagify__dropdown__item {
	box-sizing: inherit;
	padding: 0.3em 0.5em;
	margin: 1px;
	cursor: pointer;
	border-radius: 2px;
	position: relative;
	outline: 0;
}

.tagify__dropdown__item--active {
	background: var(--tagify-dd-color-primary);
	color: #fff;
}

.tagify__dropdown__item:active {
	filter: brightness(105%);
}
