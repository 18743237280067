/**
 * Variables (Custom Properties)
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

:root {
	--font-stack: inter, sans-serif;
	--font-stack-monospace: 'fira code', monospace;
	--color-gray: hsl(0deg 0% 15%);
	--color-lightgray: hsl(0deg 0% 85%);
	--color-black: hsl(0deg 0% 0%);
	--color-white: hsl(0deg 0% 100%);
	--color-neon: hsl(62deg 100% 50%);
	--color-blue: hsl(198deg 100% 36%);
	--color-brightblue: hsl(198deg 71% 49%);
	--color-darkblue: hsl(200deg 100% 25%);
	--color-red: hsl(0deg 100% 40%);
}
