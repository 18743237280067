/**
 * Figures
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

figure {
	display: block; /* correct `block` display not defined in IE 8/9 */
	margin: 0;
}

figcaption {
	display: block; /* correct `block` display not defined in IE 8/9 */
}

/* cover images */
.cover-image {
	margin: 0 -1rem;
	border-block-end: 0.5rem solid var(--color-lightgray);
}

@media (width >= 540px) {
	.cover-image {
		margin-inline: -2rem;
	}
}

@media (width >= 768px) {
	.cover-image {
		margin-inline: -4rem;
		border-block-end-width: 1rem;
	}
}
