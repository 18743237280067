/**
 * Service
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* service item */
.service-item {
	margin: 2rem -1rem 0;
	padding: 2rem 1rem;
}

.service-item + .service-item {
	margin-block-start: 0;
}

.service-item.theme-white {
	background-color: var(--color-white);
}

.service-item.theme-gray {
	background-color: hsl(from var(--color-lightgray) h s 97%);
}

.service-item.theme-blue {
	background-color: hsl(from var(--color-blue) h 65% 93%);
}

.service-item.theme-yellow {
	background-color: hsl(from var(--color-neon) h s 93%);
}

.service-item.layout-image-top {
	flex-direction: column;
}

.service-item.layout-image-top .service-item--image {
	margin: -2rem -1rem 1rem;
	text-align: center;
}

.service-item.layout-image-left .service-item--image {
	float: left;
	margin: 0 1rem 0.5rem 0;
	inline-size: 35%;
}

.service-item.layout-image-right .service-item--image {
	float: right;
	margin: 0 0 0.5rem 1rem;
	inline-size: 35%;
}

.service-item--content h2 {
	margin-block-start: 0;
}

/* service listing */
.service-listing {
	display: flex;
	flex-direction: column;
	margin: 4rem 0;
}

.service-listing--title {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	padding: 1rem;
	text-align: center;
	color: var(--color-white);
	background-color: var(--color-blue);
	box-sizing: border-box;
}

.service-listing--title h2 {
	margin: 0;
	font-size: 1.75rem;
	font-weight: 800;
	text-align: inherit;
}

.service-listing--title p {
	margin: 0;
	font-size: 0.875rem;
}

.service-listing--list {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	font-size: 1.25rem;
	text-align: center;
	list-style-type: none;
	hyphens: auto;
}

.service-listing--list li {
	margin: 1px 0 0;
}

.service-listing--list a:is(:link, :visited, :hover, :focus, :active) {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	block-size: 100%;
	color: var(--color-gray);
	font-weight: inherit;
	text-decoration: none;
	box-sizing: border-box;
	transition: background-color 0.2s linear;
}

.service-listing--list a:is(:link, :visited) {
	background-color: var(--color-lightgray);
}

.service-listing--list a:is(:hover, :focus, :active) {
	background-color: hsl(from var(--color-lightgray) h s 78%);
}

@media (width >= 375px) {
	.service-listing--title p {
		font-size: inherit;
		letter-spacing: -0.5px;
	}
}

@media (width >= 540px) {
	.service-item {
		margin-inline: -2rem;
		padding-inline: 2rem;
	}

	.service-item.layout-image-top .service-item--image {
		margin-inline: -2rem;
		text-align: center;
	}
}

@media (width >= 768px) {
	.service-item {
		display: flex;
		margin-inline: -4rem;
		padding-inline: 4rem;
	}

	.service-item.layout-image-top .service-item--image {
		margin-inline: -4rem;
	}

	.service-item.layout-image-left .service-item--image {
		float: none;
		inline-size: auto;
		flex-shrink: 0;
		margin: 0 2rem 0 0;
	}

	.service-item.layout-image-right .service-item--image {
		float: none;
		inline-size: auto;
		flex-shrink: 0;
		order: 2;
		margin: 0 0 0 2rem;
	}

	.service-listing--title h2 {
		font-size: 2.25rem;
	}
}

@media (width >= 800px) {
	.service-listing--title {
		padding-inline-start: 2rem;
		text-align: start;
	}

	.service-listing--list {
		flex-direction: row;
	}

	.service-listing--list li {
		flex-basis: 25%;
		flex-grow: 0;
		margin-block-start: 0;
		margin-inline-start: 1px;
	}
}

@media (width >= 1024px) {
	.service-listing {
		flex-direction: row;
	}

	.service-listing--title {
		flex-basis: 280px;
		padding-block: 1.25rem;
	}
}
