/**
 * Courses
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.course {
	position: relative;
}

.course--image {
	float: right;
	margin: 0 0 0.5rem 1rem;
	max-inline-size: 35%;
}

.course--price {
	margin: 1rem 0 0;
	text-align: center;
}

.course--link {
	margin: 1rem 0;
	text-align: center;
}
