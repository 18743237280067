/**
 * Typography
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* fonts */
@font-face {
	src:
		local('Inter-Regular'),
		resolve('Inter-Regular.woff2') format('woff2'),
		resolve('Inter-Regular.woff') format('woff');
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	src:
		local('Inter-SemiBold'),
		resolve('Inter-SemiBold.woff2') format('woff2'),
		resolve('Inter-SemiBold.woff') format('woff');
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

@font-face {
	src:
		local('Inter-ExtraBold'),
		resolve('Inter-ExtraBold.woff2') format('woff2'),
		resolve('Inter-ExtraBold.woff') format('woff');
	font-family: Inter;
	font-style: normal;
	font-weight: 800;
	font-display: optional;
}

@font-face {
	src:
		local('Caveat-Regular'),
		resolve('Caveat-Regular.woff2') format('woff2'),
		resolve('Caveat-Regular.woff') format('woff');
	font-family: Caveat;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

/* typographic defaults */
html {
	font: 16px/1.5 var(--font-stack);
	text-size-adjust: 100%; /* prevents text size adjust after orientation change */
	quotes: '„' '“' '‚' '‘';
}

/* paragraphs and addresses */
p,
address {
	margin: 1rem 0 0;
}

/* emphasis */
em {
	font-style: normal;
	font-weight: 600;
	color: var(--color-blue);
}

strong {
	font-weight: 600;
}

/* sub and sup */
sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	inset-block-start: -0.5em;
}

sub {
	inset-block-end: -0.25em;
}

/* forced no wrapping */
.no-break {
	white-space: nowrap;
}

/* alignments */
.align-center {
	text-align: center;
}

.align-right {
	text-align: end;
}

/* floats */
.float-left {
	float: left;
}

.float-right {
	float: right;
}

/* vertical alignment helpers */
.va-up-mini {
	vertical-align: -0.125em;
}

.va-up-micro {
	vertical-align: -0.0625em;
}
