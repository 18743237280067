/**
 * Multiple Columns
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.multi-column .contact-box ~ * {
	margin-block-start: 2rem;
	text-align: center;
}

@media (width >= 768px) {
	.multi-column .contact-box ~ * {
		margin-block-start: 1rem;
		text-align: center;
	}
}

@media (width >= 1024px) {
	.multi-column {
		display: grid;
		grid-template: auto / repeat(2, calc(50% - 0.5rem));
		grid-gap: 1rem;
		margin: 3rem 0 0;
	}

	.multi-column .contact-box {
		grid-column: 1;
		grid-row: 1 / span 2;
	}

	.multi-column .contact-box ~ * {
		margin-block-start: 0;
		text-align: end;
	}
}
