/**
 * Header
 * @project PIBSI
 * @author Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* header */
header {
	grid-area: header;
	display: block; /* correct `block` display not defined in IE 8/9 */
	padding: 1rem;
}

header svg {
	inline-size: 100%;
	max-inline-size: 230px;
	block-size: auto;
}

@media (width >= 768px) {
	header {
		margin: -2rem 0 0;
		padding: 2rem 2.5rem;
		border-radius: 1.5rem;
		max-inline-size: 360px;
		text-align: center;
		color: var(--color-blue);
		background-color: var(--color-white);
		box-sizing: border-box;
	}

	header svg {
		max-inline-size: none;
	}
}

@media (width >= 1110px) {
	header {
		max-inline-size: none;
	}
}

@media print {
	header {
		text-align: end;
	}
}
